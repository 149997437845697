<template>
  <div class="nav-container">
    <div
      v-if="showLabels"
      class="background-view"
      :class="this.open ? 'bg-view-on' : ''"
    ></div>
    <!--Navbar-->
    <button class="navbar-toggler" type="button" @click="toggleNavbarIcon">
      <div class="animated-icon3" :class="this.open ? 'open' : ''">
        <span></span><span></span><span></span>
      </div>
    </button>
    <div class="labels-container" v-if="showLabels">
      <button class="ref-btn" @click="scrollToAbout">
        <h3 class="label">RÓLUNK</h3>
      </button>
      <button class="ref-btn" @click="scrollToServices">
        <h3 class="label-2">SZOLGÁLTATÁSOK</h3>
      </button>
      <button class="ref-btn" @click="scrollToPricing">
        <h3 class="label-4">DÍJSZABÁS</h3>
      </button>
      <button class="ref-btn" @click="scrollToContact">
        <h3 class="label-3">ELÉRHETŐSÉGEK</h3>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      open: false,
      showLabels: false,
      animate: false,
    };
  },
  methods: {
    toggleNavbarIcon() {
      this.open = !this.open;

      this.animate = !this.animate;
      if (this.animate) {
        this.$nextTick(() => {
          this.showLabels = true;
        });
      } else {
        this.showLabels = false;
      }
    },
    scrollToAbout() {
      this.$emit("about-scroll");
      this.toggleNavbarIcon();
    },
    scrollToContact() {
      this.$emit("contact-scroll");
      this.toggleNavbarIcon();
    },
    scrollToServices() {
      this.$emit("services-scroll");
      this.toggleNavbarIcon();
    },
    scrollToPricing() {
      this.$emit("pricing-scroll");
      this.toggleNavbarIcon();
    },
  },
};
</script>

<style scoped>
.background-view {
  position: fixed !important;
  top: 0;
  left: 0;
  background-color: rgba(142, 142, 142, 0.4);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: -1;
  opacity: 0;
}
.bg-view-on {
  opacity: 1;
  transition: opacity 1s;
}
h3 {
  text-align: right;
  color: black;
}

.navbar-toggler {
  margin-bottom: 50px;
}

.animated-icon3 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.animated-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.animated-icon3 span {
  background: rgb(36, 38, 61);
}

.animated-icon3 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3 span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3 span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0px;
  left: 8px;
}

.animated-icon3.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.animated-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 21px;
  left: 8px;
}
.nav-link {
  color: black;
}

.labels-container {
  display: flex;
  flex-direction: column;
}

.label {
  animation-name: example;
  animation-duration: 0.6s;
}
.label-2 {
  animation-name: example;
  animation-duration: 0.6s;
}
.label-3 {
  animation-name: example;
  animation-duration: 0.6s;
}
.label-4 {
  animation-name: example;
  animation-duration: 0.6s;
}

@keyframes example {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.ref-btn {
  background-color: transparent;
  border: 0;
}
</style>