<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 description">
                <h2>{{this.name}}</h2>
                <h3>{{this.occupation}}</h3>
                <p>{{this.description}}</p>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 image">
                <img class="img-fluid" :src="getImg(this.imgName)" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "ProfileRight",
    props: {
        name: String,
        occupation: String,
        description: String,
        imgName: String,
    },
    methods: {
        getImg(imgName) {
            return require(`../assets/${imgName}`);
        }
    }
}
</script>

<style scoped>

.img-fluid {
    width: 70%;
}
h2 {
    margin-top:10px;
    margin-bottom: 0px;
}
h3 {
    font-size: 20px;
    margin-bottom: 10px;
}
p {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    white-space: pre-line !important;
}
@media screen and (max-width: 991px) {
    .row {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 576px) {
    p {
        width: 90%;
        text-align: justify;
    }
}

/*  small */
@media screen and (min-width: 577px) and (max-width: 767px) {
    p {
        width: 90%;
        text-align: justify;
    }
}
</style>