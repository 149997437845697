import { createApp } from 'vue'
import App from './App.vue'
import BootstrapVue3 from 'bootstrap-vue-3';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App);

app.use(BootstrapVue3);

app.use(VueGoogleMaps, {load: {key: 'AIzaSyCfftuI9kK6zYa8bsG5qUFc8AnHYPOHskQ'}})

app.mount("#app");