<template>
  <div class="row">
    <h1 class="service-fee-title">DÍJSZABÁS</h1>
    <p class="service-bottom-label">
      Az egyes ügyekben a díjszabás egyedi megállapodás tárgyát képezi.
    </p>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Pricing",
};
</script>

<style scoped>
h1 {
  padding-bottom: 50px;
  padding-top: 50px;
}
h4 {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 22px;
}
p {
  font-size: 17px;
}
.row {
  margin-left: 100px;
  margin-right: 100px;
}
li {
  text-align: left;
}

.service-name {
  font-size: 25px;
}
.service-price {
  font-size: 25px;
  font-weight: bold;
}

.service-bottom-label {

  font-size: 25px;
}

.service-fee-title {
  padding-bottom: 50px;
  padding-top: 0;
}

@media screen and (max-width: 576px) {
  h1 {
    padding-bottom: 30px;
  }
  .service-fee-title {
    padding-bottom: 30px;
  }
  .row {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-12 {
    margin-bottom: 15px;
  }
  .service-name {
    font-size: 20px;
  }
  .service-price {
    font-size: 20px;
  }
  .service-bottom-label {
    margin-top: 10px;
  }
}

/*  small */
@media screen and (min-width: 577px) and (max-width: 767px) {
  h1 {
    padding-bottom: 70px;
  }
  .service-fee-title {
    padding-bottom: 30px;
  }
  .row {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-12 {
    margin-bottom: 15px;
  }
}
/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .service-fee-title {
    padding-top: 0;
    padding-bottom: 50px;
  }
}
</style>