<template>
  <div>
    <h1>SZOLGÁLTATÁSOK</h1>
    <div class="row">
      <div class="col-12 col-lg-4 col-xl-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="40"
          width="40"
          viewBox="0 0 512 512"
        >
          <path
            d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"
          />
        </svg>
        <h4>TÁRSASÁGI JOGI ÜGYEK</h4>
        <ul>
          <li>
            gazdasági társaságok és egyesületek, alapítványok alapítása,
            módosítása
          </li>
          <li>
            jogi személyek működéséhez kapcsolódó ügyek ellátása, képviselete,
            átvilágítása
          </li>
          <li>bizalmi vagyonkezelés</li>
          <li>munkajogi ügyek</li>
        </ul>
      </div>
      <div class="col-12 col-lg-4 col-xl-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32"
          width="40"
          viewBox="0 0 640 512"
        >
          <path
            d="M240 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM192 48a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32 80c17.7 0 32 14.3 32 32h8c13.3 0 24 10.7 24 24v16c0 1.7-.2 3.4-.5 5.1C280.3 229.6 320 286.2 320 352c0 88.4-71.6 160-160 160S0 440.4 0 352c0-65.8 39.7-122.4 96.5-146.9c-.4-1.6-.5-3.3-.5-5.1V184c0-13.3 10.7-24 24-24h8c0-17.7 14.3-32 32-32zm0 320a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm192-96c0-25.9-5.1-50.5-14.4-73.1c16.9-32.9 44.8-59.1 78.9-73.9c-.4-1.6-.5-3.3-.5-5.1V184c0-13.3 10.7-24 24-24h8c0-17.7 14.3-32 32-32s32 14.3 32 32h8c13.3 0 24 10.7 24 24v16c0 1.7-.2 3.4-.5 5.1C600.3 229.6 640 286.2 640 352c0 88.4-71.6 160-160 160c-62 0-115.8-35.3-142.4-86.9c9.3-22.5 14.4-47.2 14.4-73.1zm224 0a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM368 0a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm80 48a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
          />
        </svg>
        <h4>BÜNTETŐ ÜGYEK</h4>
        <ul>
          <li>
            védelem ellátása terhelti oldalon büntető- és szabálysértési
            eljárásban
          </li>
          <li>büntetés-végrehajtási ügyek</li>
          <li>
            jogi képviselet ellátása sértetti és egyéb érdekelti oldalon,
            valamint polgári jogi igény érvényesítése során
          </li>
          <li>
            bűncselekménnyel okozott kárral kapcsolatos perben jogi képviselet
            ellátása
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-4 col-xl-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32"
          width="40"
          viewBox="0 0 640 512"
        >
          <path
            d="M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L512 316.8V128h-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48V352h28.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123zM16 128c-8.8 0-16 7.2-16 16V352c0 17.7 14.3 32 32 32H64c17.7 0 32-14.3 32-32V128H16zM48 320a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM544 128V352c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V144c0-8.8-7.2-16-16-16H544zm32 208a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"
          />
        </svg>
        <h4>SZEMÉLYHEZ FŰZŐDŐ ÜGYEK</h4>
        <ul>
          <li>öröklési ügyek</li>
          <li>családjogi ügyek</li>
          <li>személyiségi jogi ügyek</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4 col-xl-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="40"
          width="40"
          viewBox="0 0 512 512"
        >
          <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.-->
          <path
            d="M318.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-120 120c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l4-4L325.4 293.4l-4 4c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l120-120c12.5-12.5 12.5-32.8 0-45.3l-16-16c-12.5-12.5-32.8-12.5-45.3 0l-4 4L330.6 74.6l4-4c12.5-12.5 12.5-32.8 0-45.3l-16-16zm-152 288c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l48 48c12.5 12.5 32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-1.4-1.4L272 285.3 226.7 240 168 298.7l-1.4-1.4z"
          />
        </svg>
        <h4>POLGÁRI PERES ÉS NEMPERES IGÉNYÉRVÉNYESÍTÉS</h4>
        <ul>
          <li>kártérítési ügyek</li>
          <li>szerződésekkel kapcsolatos ügyek</li>
        </ul>
      </div>
      <div class="col-12 col-lg-4 col-xl-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="40"
          widhth="40"
          viewBox="0 0 576 512"
        >
          <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c.2 35.5-28.5 64.3-64 64.3H128.1c-35.3 0-64-28.7-64-64V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L416 100.7V64c0-17.7 14.3-32 32-32h32c17.7 0 32 14.3 32 32V185l52.8 46.4c8 7 12 15 11 24zM248 192c-13.3 0-24 10.7-24 24v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V216c0-13.3-10.7-24-24-24H248z"
          />
        </svg>
        <h4>INGATLANJOG</h4>
        <ul>
          <li>
            adásvételi, ajándékozási, bérleti, csere- és egyéb ingatlanokkal
            kapcsolatos szerződések szerkesztése
          </li>
          <li>ingatlanokkal kapcsolatos peres és nemperes igényérvényesítés</li>
        </ul>
      </div>
      <div class="col-12 col-lg-4 col-xl-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="40"
          widhth="40"
          viewBox="0 0 576 512"
        >
          <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M112 112c0 35.3-28.7 64-64 64V336c35.3 0 64 28.7 64 64H464c0-35.3 28.7-64 64-64V176c-35.3 0-64-28.7-64-64H112zM0 128C0 92.7 28.7 64 64 64H512c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM176 256a112 112 0 1 1 224 0 112 112 0 1 1 -224 0zm80-48c0 8.8 7.2 16 16 16v64h-8c-8.8 0-16 7.2-16 16s7.2 16 16 16h24 24c8.8 0 16-7.2 16-16s-7.2-16-16-16h-8V208c0-8.8-7.2-16-16-16H272c-8.8 0-16 7.2-16 16z"
          />
        </svg>
        <h4>KÖVETELÉSKEZELÉS</h4>
        <ul>
          <li>kölcsönszerződés</li>
          <li>
            követelésbehajtás (fizetési felszólítás, fizetési meghagyás, csőd-
            és felszámolási eljárás, valamint végelszámolás)
          </li>
          <li>végrehajtási eljárásokban történő jogi képviselet</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          height="40"
          widhth="40"
        >
          <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M482.3 192c34.2 0 93.7 29 93.7 64c0 36-59.5 64-93.7 64l-116.6 0L265.2 495.9c-5.7 10-16.3 16.1-27.8 16.1l-56.2 0c-10.6 0-18.3-10.2-15.4-20.4l49-171.6L112 320 68.8 377.6c-3 4-7.8 6.4-12.8 6.4l-42 0c-7.8 0-14-6.3-14-14c0-1.3 .2-2.6 .5-3.9L32 256 .5 145.9c-.4-1.3-.5-2.6-.5-3.9c0-7.8 6.3-14 14-14l42 0c5 0 9.8 2.4 12.8 6.4L112 192l102.9 0-49-171.6C162.9 10.2 170.6 0 181.2 0l56.2 0c11.5 0 22.1 6.2 27.8 16.1L365.7 192l116.6 0z"
          />
        </svg>
        <h4>BEVÁNDORLÁSI ÉS IDEGENRENDÉSZETI ÜGYEK</h4>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Services",
};
</script>
<style scoped>
h1 {
  padding-bottom: 50px;
  padding-top: 50px;
}
h4 {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 22px;
}
p {
  font-size: 17px;
}
.row {
  margin-bottom: 70px;
  margin-left: 100px;
  margin-right: 100px;
}
li {
  text-align: left;
}

.service-name {
  font-size: 25px;
}
.service-price {
  font-size: 25px;
  font-weight: bold;
}

.service-bottom-label {
  margin-top: 50px;
}

.service-fee-title {
  padding-bottom: 0;
  padding-top: 0;
}

@media screen and (max-width: 576px) {
  h1 {
    padding-bottom: 30px;
  }
  .service-fee-title {
  padding-bottom: 30px;
}
  .row {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-12 {
    margin-bottom: 15px;
  }
  .service-name {
    font-size: 20px;
  }
  .service-price {
    font-size: 20px;
  }
  .service-bottom-label {
    margin-top: 10px;
  }
}

/*  small */
@media screen and (min-width: 577px) and (max-width: 767px) {
  h1 {
    padding-bottom: 70px;
  }
  .service-fee-title {
    padding-bottom: 30px;
  }
  .row {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0;
  }
  .col-12 {
    margin-bottom: 15px;
  }
}
/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .service-fee-title {
    padding-top: 0;
    padding-bottom: 50px;
  }
}
</style>