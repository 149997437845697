<template>
  <div>
    <div class="top">
      <img class="background-img" src="./assets/castle2.jpg" alt="" />

      <img class="ugk-img img-fluid" src="./assets/ugk1.svg" alt="" />
      <SocialContainer class="social-container" />
    </div>
    <div ref="about">
      <About />
    </div>
    <div class="img-fill">
      <img class="img-fluid justitia" src="./assets/justitia.png" alt="" />
    </div>
    <div ref="services">
      <Services />
    </div>
    <div ref="pricing">
      <Pricing />
    </div>
    <div class="img-fill">
      <img class="img-fluid law2" src="./assets/law2.png" alt="" />
    </div>
    <div ref="contact">
      <Contact />
    </div>
    <div class="bottom-info">
      <p class="bottom-info-text">
        Ezt a honlapot dr. Udrea Roland egyéni ügyvéd (Budapesti Ügyvédi Kamara,
        kamarai azonosító száma: 36079314) és dr. Gagó-Kilbinger András egyéni
        ügyvéd (Budapesti Ügyvédi Kamara, kamarai azonosító száma: 36080777),
        mint az Udrea és Gagó-Kilbinger Ügyvédi Társulás tagjai tartják fenn az
        ügyvédekre vonatkozó jogszabályok és belső szabályok szerint, amelyek az
        ügyféljogokra vonatkozó tájékoztatással együtt a
        www.magyarugyvedikamara.hu weboldalon találhatóak. <br />
        <br />
        A www.ugklegal.com weboldal tartalma és elemei szerzői jogi védelem
        alatt állnak, minden ehhez fűződő jogot fenntartunk. Az UGK Ügyvédi
        Társulás írásbeli hozzájárulása nélkül a közzétett anyagok bármilyen
        nemű felhasználása tilos. <br />
        <br />
        Az oldal tartalma tájékoztató jellegű, nem helyettesíti a jogi
        tanácsadást.
      </p>
    </div>
    <NavBar
      class="nav-bar"
      @about-scroll="scrollToAbout"
      @contact-scroll="scrollToContact"
      @services-scroll="scrollToServices"
      @pricing-scroll="scrollToPricing"
    />
  </div>
</template>

<script>
// AIzaSyADZqMugp-KlKxzbhZtzP5YIYvJOXqUvXA
import About from "./components/About.vue";
import Contact from "./components/Contact.vue";
import NavBar from "./components/NavBar.vue";
import Pricing from './components/Pricing.vue';
import Services from "./components/Services.vue";
import SocialContainer from "./components/SocialContainer.vue";

export default {
  name: "App",
  components: {
    NavBar,
    About,
    SocialContainer,
    Services,
    Contact,
    Pricing,
  },
  methods: {
    scrollToServices() {
      const el = this.$refs.services;
      el.scrollIntoView({
        behavior: "smooth",
      });
    },
    scrollToAbout() {
      const el = this.$refs.about;
      el.scrollIntoView({
        behavior: "smooth",
      });
    },
    scrollToContact() {
      const el = this.$refs.contact;
      el.scrollIntoView({
        behavior: "smooth",
      });
    },
    scrollToPricing() {
      const el = this.$refs.pricing;
      el.scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&display=swap");
#app {
  font-family: "Baskervville", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
html,
body {
  overflow-x: hidden !important;
}
.top {
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-bar {
  position: fixed;
  top: 50px;
  right: 50px;
  text-align: right;
}
.background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 750px;
  object-fit: cover;
  z-index: -1;
}
.ugk-img {
  position: absolute;
  top: 25px;
  left: 30px;
  width: 50%;
}

.social-container {
  position: absolute;
  width: fit-content;
  top: 45px;
  right: 120px;
}

.vue-map {
  margin-left: auto;
  margin-right: auto;
  width: 700px !important;
  height: 500px !important;
}

.bottom-info {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.bottom-info-text {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}

.law2 {
  margin-top: 50px;
}

@media screen and (max-width: 700px) {
  .top {
    height: 750px;
  }
  .social-container {
    display: none;
  }
  .vue-map {
    width: 100% !important;
  }
}
@media screen and (max-width: 599px) {
  .nav-bar {
    top: 40px;
  }
  .bottom-info-text {
    width: 100%;
  }
}

/*  small */
@media screen and (min-width: 577px) and (max-width: 767px) {
  .top {
    height: 750px;
  }
  .bottom-info-text {
    width: 90%;
  }
  .ugk-img {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 40%;
  }
}
/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .bottom-info-text {
    width: 90%;
  }
  .ugk-img {
    width: 35%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1300px) {
  .ugk-img {
    width: 30%;
  }
}
@media screen and (min-width: 1301px) {
  .ugk-img {
    width: 20%;
  }
}
</style>
