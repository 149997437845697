<template>
  <div>
    <h1>RÓLUNK</h1>
    <ProfileRight
      class="profile"
      name="Dr. Udrea Roland"
      occupation="ügyvéd"
      imgName="roland.jpeg"
      description="Jogászként 2018. évben diplomáztam az Eötvös Loránd Tudományegyetem Állam- és Jogtudományi Karán. Egyetemi tanulmányaim során aktívan foglalkoztam a büntetőjog és büntetés-végrehajtási jog területével. Szakmai gyakorlatomat az ügyészségen és egy neves - leginkább büntető ügyekkel foglalkozó - budapesti ügyvédi irodában töltöttem. Ügyvédjelöltként és alkalmazott ügyvédként 2018. év és 2023. év között egy dinamikusan fejlődő vegyes praxisú budapesti ügyvédi irodában dolgoztam, ahol magas szinten sajátítottam el az ügyvédi hivatáshoz szükséges valamennyi tudást és készséget számos jogterületen. 2023. évben jeles minősítéssel szakvizsgáztam.
      
      Állok szíves rendelkezésre valamennyi Ügyfelem számára bármely jogi problémájuk vagy kérdésük esetén. A legfőbb jogterületeim közé tartozik a büntetőjog (ideértve a szabálysértési jogot is), illetve a büntetés-végrehajtási jog, az ingatlanjog, a cégjog, valamint a családjog, továbbá a munkajog és a végrehajtási jog. Munkám során Ügyfeleim számára teljeskörű jogi szolgáltatást kívánok nyújtani, ahol a bizalom, kreativitás, pontosság és precizitás kiemelkedő figyelmet kap."
    />
    <ProfileLeft
      class="profile"
      name="Dr. Gagó-Kilbinger András"
      occupation="ügyvéd, kártérítési jogi szakjogász"
      imgName="andras.jpeg"
      description="Jogi diplomámat 2019-ben az Eötvös Loránd Tudományegyetem Állam- és Jogtudományi Karán szereztem. Szakmai gyakorlatomat az ország egyik legpatinánsabb - büntetőügyekre specializálódott – ügyvédi irodájában töltöttem. Ügyvédjelöltként 2019 és 2023 között különösképpen büntetőjog, kártérítési jog és személyhez fűződő jogok, ingatlanjog és társasági jog területén tettem szert gyakorlatra. 2022-ben kártérítési jogi szakjogász végzettséget szereztem a Pázmány Péter Katolikus Egyetem Jog- és Államtudományi Karán, utóbbi képzésen kutatási területem az ún. vis maior események bizonyításának bírói gyakorlata volt.
      
      Praxisom központi jogterületei közé tartozik a büntetőjog, az ingatlanjog, a társasági jog, valamint kártérítés és sérelemdíj iránti igények érvényesítése, továbbá a fizetésképtelenségi jog és követeléskezelés.
      
      Praxisom legfontosabb alapelvei a bizalom, diszkréció és a kérlelhetetlen perfekcionizmus."
    />
    <h2 class="egyuttmukodo-szemelyek">Együttműködő személyek</h2>
    <ProfileRight
      class="profile"
      name="Pintaudi Alain"
      occupation="angol és olasz nyelvi lektor"
      imgName="alain.jpeg"
      description="Magamat leginkább, ha be kéne kategorizálni stratégiai tanácsadó elnevezéssel illetném, mellette fordító és tolmács vagyok. Nevem Pintaudi Alain kétnyelvű olasz-magyar családban születtem, így egyedülálló perspektívát hozok a tanácsadás és az üzleti élet világába. A nyelvek iránti múltamnak és szeretetemnek köszönhetően folyékonyan beszélek angolul és olaszul, profi és lelkes fordító vagyok, a hangsúlyt nemcsak a nyelvi szakadékok áthidalására, hanem a hatékonyság elősegítésére is helyezem.
      
      Elkötelezettje vagyok a személyre szabott megoldások kidolgozásának, amelyek a vállalkozásokat a termelékenység új magasságaiba emelik. Az innováció és a nyitott szemlélet mellett elkötelezett vagyok a megoldásközpontúság mellett, biztosítva, hogy minden tanácsadói tevékenység kézzelfogható és tartós sikerhez vezessen. Üzletorientált gondolkodásmódom nem csak a termelékenység fokozását szolgálja, hanem a részletekre való élesebb odafigyelést is. 
      
      Innovatív stratégiák és nyitott szemlélet révén a költségek optimalizálására törekszem, miközben a lehető legjobb végkimenetel/végeredmény kalkulációjával növelem egy cég nyereséget/ profitját. Elkötelezettségem nem csupán a tanácsadás és a támogatás, hanem a vállalkozások átalakítása, a technológiai naprakészség segítésével es megvalósításával, egy folyamatos fejlődésben lévő piacon a tartós siker érdekében."
    />
  </div>
</template>

<script>
import ProfileLeft from "./ProfileLeft.vue";
import ProfileRight from "./ProfileRight.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "About",
  components: {
    ProfileRight,
    ProfileLeft,
  },
};
</script>

<style scoped>
h1 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.profile {
  margin-bottom: 60px;
}

.egyuttmukodo-szemelyek{
  margin-bottom: 20px;

}

@media screen and (max-width: 576px) {
  h1 {
    padding-top: 0px;
    padding-bottom: 30px;
  }
}

/*  small */
@media screen and (min-width: 577px) and (max-width: 767px) {
  h1 {
    padding-top: 0px;
    padding-bottom: 30px;
  }
}
/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
}
</style>