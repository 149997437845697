<template>
  <div class="contact-main-container">
    <h1 class="top-title">ELÉRHETŐSÉGEK</h1>
    <div class="row contact-detail">
      <div class="col">
        <img class="img-fluid logo-img" src="../assets/logo.svg" alt="" />
        <h2>Udrea & Gagó-Kilbinger Ügyvédi Társulás</h2>
        <p>1013 Budapest, Attila út 22. 4. emelet 21.</p>
        <p>(kapucsengő: 21.)</p>
        <div class="row">
          <div class="col-12 col-lg-6 col-xl-6 container-contact-lawyer">
            <h5 class="contact-info-field">Dr. Udrea Roland</h5>
            <h5 class="contact-info-field">ügyvéd</h5>
            <h5 class="contact-info-field">+3670 657 7747</h5>
            <h5 class="contact-info-field">udrea@ugklegal.com</h5>
          </div>
          <div class="col-12 col-lg-6 col-xl-6 container-contact-lawyer">
            <h5 class="contact-info-field">Dr. Gagó-Kilbinger András LL.M.</h5>
            <h5 class="contact-info-field">ügyvéd</h5>
            <h5 class="contact-info-field">+3630 232 2477</h5>
            <h5 class="contact-info-field">agk@ugklegal.com</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row contact-detail transport-detail-container">
      <div class="col-12 col-lg-6 col-xl-6 transport-detail">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="64"
          width="72"
          viewBox="0 0 576 512"
        >
          <path
            d="M288 0C422.4 0 512 35.2 512 80V96l0 32c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32l0 160c0 17.7-14.3 32-32 32v32c0 17.7-14.3 32-32 32H416c-17.7 0-32-14.3-32-32V448H192v32c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32l0-32c-17.7 0-32-14.3-32-32l0-160c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h0V96h0V80C64 35.2 153.6 0 288 0zM128 160v96c0 17.7 14.3 32 32 32H272V128H160c-17.7 0-32 14.3-32 32zM304 288H416c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H304V288zM144 400a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm288 0a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM384 80c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16s7.2 16 16 16H368c8.8 0 16-7.2 16-16z"
          />
        </svg>
        <h5>TÖMEGKÖZELEKEDÉSSEL</h5>
        <p class="address-info-field">
          Irodánk megközelíthető az M2 metróval a Déli Pályaudvar végállomástól,
          az 56-56/A megjelölésű villamossal a Mikó utca megállótól és számos
          egyéb buszjárattal a Krisztina tér megállótól (16, 105, 178, 210, 216)
          néhány perc sétával és 5 megjelölésű busszal, amely a Mikó utca
          megállóban közvetlenül az iroda mellett áll meg.
        </p>
      </div>
      <div class="col-12 col-lg-6 col-xl-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="64"
          width="64"
          viewBox="0 0 512 512"
        >
          <path
            d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
          />
        </svg>
        <h5>AUTÓVAL</h5>
        <p class="address-info-field">
          Irodánk közvetlen közelében számos felszíni parkolási lehetőség van, a
          parkolás fizetős.
        </p>
      </div>
    </div>
    <GMapMap
      class="gmap-view"
      :center="center"
      :zoom="15"
      map-type-id="roadmap"
      style="width: 500px; height: 300px"
    >
      <GMapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="center = m.position"
      >
        <GMapInfoWindow :opened="true">
          <div>Attila út 22. 4. emelet 21.</div>
        </GMapInfoWindow>
      </GMapMarker>
    </GMapMap>
    <h1 class="bottom-title">KAPCSOLAT</h1>
    <p class="cta">
      Amennyiben jogi kérdése vagy problémája merülne fel, úgy az UGK Ügyvédi
      Társulás áll szíves rendelkezésére. A konzultáció időpont egyeztetés
      esetén személyesen és online is lehetséges. Sürgős, halaszthatatlan
      esetben hétvégén és éjjel is elérhetőek vagyunk.
    </p>
    <div class="contact-form container">
      <form action="https://submit-form.com/smmhwr7Yb">
        <div class="row">
          <div class="col-12 col-lg-6 col-xl-6">
            <input name="Név" type="text" placeholder="NÉV" />
          </div>
          <div class="col-12 col-lg-6 col-xl-6">
            <input name="Email" type="text" placeholder="E-MAIL" />
          </div>
          <div class="col-12 message-area">
            <textarea
              name="Üzenet"
              id="message"
              cols="30"
              rows="10"
              placeholder="ÜZENET"
            ></textarea>
          </div>
        </div>
        <button type="submit" class="btn btn-secondary">KÜLDÉS</button>
      </form>
    </div>
    <img class="img-fluid bottom-bg-img" src="../assets/bg.png" alt="" />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Contact",
  data() {
    return {
      // 47.49895568865369, 19.03097856858631
      center: { lat: 47.49895568865369, lng: 19.03097856858631 },
      markers: [
        {
          position: {
            lat: 47.49895568865369,
            lng: 19.03097856858631,
          },
        },
      ],
    };
  },
};
</script>

<style scoped>
.top-title {
  padding-bottom: 50px;
  padding-top: 50px;
}
h2 {
  font-size: 28px;
}
h5 {
  margin-top: 20px;
  margin-bottom: 0;
}
.logo-img {
  width: 15%;
}
p {
  font-size: 20px;
}
.contact-detail {
  margin-bottom: 0;
}
.gmap-view {
  margin-top: 50px;
  margin-bottom: 50px;
}
.bottom-title {
  padding-bottom: 30px;
}
.cta {
  width: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.contact-form {
  width: 60%;
}

input {
  width: 100%;
  height: 40px;
}
textarea {
  width: 100%;
}

.message-area {
  margin-top: 20px;
}
.btn-secondary {
  width: 25%;
  margin-top: 20px;
  margin-bottom: 50px;
}

.contact-main-container {
  position: relative;
}
.bottom-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  z-index: -1;
}

.contact-info-field {
  margin-top: 0;
  margin-bottom: 0;
}

.address-info-field {
  margin-left: 80px;
  margin-right: 80px;
}

@media screen and (max-width: 499px) {
  .logo-img {
    width: 40%;
  }
  .contact-form {
    width: 100%;
  }
  input {
    margin-bottom: 10px;
  }
  .top-title {
    padding-bottom: 30px;
  }
  .transport-detail {
    padding-bottom: 50px;
  }
  .container-contact-lawyer {
    padding-bottom: 50px;
  }
  .cta {
    width: 100%;
  }
  .address-info-field {
    margin-left: 20px;
    margin-right: 20px;
  }
  .btn-secondary {
    width: initial;
  }
}
/*  small */
@media screen and (min-width: 500px) and (max-width: 767px) {
  .logo-img {
    width: 25%;
  }
  .contact-form {
    width: 90%;
  }
  input {
    margin-bottom: 10px;
  }
  .top-title {
    padding-bottom: 30px;
  }
  .transport-detail {
    padding-bottom: 50px;
  }
  .container-contact-lawyer {
    padding-bottom: 50px;
  }
  .cta {
    width: 100%;
  }
  .gmap-view {
    margin-top: 100px;
    margin-bottom: 70px;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .logo-img {
    width: 20%;
  }
  .contact-form {
    width: 70%;
  }
  input {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 992px) {
  .transport-detail-container {
    margin-top: 30px;
  }
}
@media screen and (min-width: 1400px) {
  .contact-form {
    width: 50%;
  }
}
</style>